var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',[_c('validation-observer',{ref:"observer",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var invalid = ref.invalid;
return [_c('form',{on:{"submit":function($event){$event.preventDefault();return _vm.submit($event)}}},[_c('v-card',{attrs:{"elevation":"24"}},[_c('v-card-title',[_vm._v(" "+_vm._s(_vm.$t('registration.title'))+" ")]),_c('v-card-text',[_c('validation-provider',{attrs:{"name":"name","rules":{ required: true, custom_alpha_string: /^[A-Za-zÀ-ž0-9 -]*$/ }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"error-messages":errors,"label":_vm.$t('registration.name')},model:{value:(_vm.name),callback:function ($$v) {_vm.name=$$v},expression:"name"}})]}}],null,true)}),_c('validation-provider',{attrs:{"name":"surname","rules":{ required: true, custom_alpha_string: /^[A-Za-zÀ-ž0-9 -]*$/ }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"error-messages":errors,"label":_vm.$t('registration.surname')},model:{value:(_vm.surname),callback:function ($$v) {_vm.surname=$$v},expression:"surname"}})]}}],null,true)}),_c('validation-provider',{attrs:{"name":"company","rules":{ required: true, custom_ico:8 }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-autocomplete',{attrs:{"loading":_vm.ico_is_loading,"items":_vm.ico_items,"search-input":_vm.ico_search,"error-messages":errors,"label":_vm.$t('registration.ico'),"item-text":"ico","item-value":"ico","return-object":"","hide-no-data":"","clearable":""},on:{"update:searchInput":function($event){_vm.ico_search=$event},"update:search-input":function($event){_vm.ico_search=$event}},scopedSlots:_vm._u([{key:"item",fn:function(ref){
var item = ref.item;
return [_c('v-list-item-content',[_c('v-list-item-title',{domProps:{"textContent":_vm._s(item.company)}}),_c('v-list-item-subtitle',{domProps:{"textContent":_vm._s(item.address)}})],1)]}}],null,true),model:{value:(_vm.company),callback:function ($$v) {_vm.company=$$v},expression:"company"}})]}}],null,true)}),_c('v-text-field',{attrs:{"label":_vm.$t('registration.company'),"disabled":""},model:{value:(_vm.ico_details),callback:function ($$v) {_vm.ico_details=$$v},expression:"ico_details"}}),_c('validation-provider',{attrs:{"name":"position","rules":{ required: true, custom_alpha_string: /^[A-Za-zÀ-ž0-9 -]*$/ }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"error-messages":errors,"label":_vm.$t('registration.position')},model:{value:(_vm.position),callback:function ($$v) {_vm.position=$$v},expression:"position"}})]}}],null,true)}),_c('validation-provider',{attrs:{"name":"telephone","rules":{ required: true, custom_telephone: /^\+421[0-9]{9,9}$/ }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"error-messages":errors,"label":_vm.$t('registration.telephone')},model:{value:(_vm.telephone),callback:function ($$v) {_vm.telephone=$$v},expression:"telephone"}})]}}],null,true)}),_c('validation-provider',{attrs:{"name":"email","rules":"required|email"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"error-messages":errors,"label":_vm.$t('registration.email')},model:{value:(_vm.email),callback:function ($$v) {_vm.email=$$v},expression:"email"}})]}}],null,true)}),_c('validation-provider',{attrs:{"name":"password","rules":"required","vid":"pass_confirmation"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"error-messages":errors,"label":_vm.$t('registration.password'),"type":_vm.show1 ? 'text' : 'password',"append-icon":_vm.show1 ? 'mdi-eye' : 'mdi-eye-off'},on:{"click:append":function($event){_vm.show1 = !_vm.show1}},model:{value:(_vm.password),callback:function ($$v) {_vm.password=$$v},expression:"password"}})]}}],null,true)}),_c('validation-provider',{attrs:{"name":"password_confirm","rules":"required|custom_password:pass_confirmation"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"error-messages":errors,"label":_vm.$t('registration.password_confirm'),"type":_vm.show2 ? 'text' : 'password',"append-icon":_vm.show1 ? 'mdi-eye' : 'mdi-eye-off'},on:{"click:append":function($event){_vm.show2 = !_vm.show2}},model:{value:(_vm.password_confirm),callback:function ($$v) {_vm.password_confirm=$$v},expression:"password_confirm"}})]}}],null,true)}),_c('v-radio-group',{attrs:{"label":_vm.$t('registration.enews')},model:{value:(_vm.enews),callback:function ($$v) {_vm.enews=$$v},expression:"enews"}},[_c('v-radio',{attrs:{"label":_vm.$t('registration.enews_yes'),"value":"yes","checked":""}}),_c('v-radio',{attrs:{"label":_vm.$t('registration.enews_no'),"value":"no"}})],1),_c('validation-provider',{attrs:{"rules":{ custom_gdpr: { allowFalse: false } },"name":"gdpr"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-checkbox',{staticClass:"justifikovane",attrs:{"error-messages":errors,"value":"yes","label":_vm.$t('registration.gdpr'),"type":"checkbox"},model:{value:(_vm.gdpr),callback:function ($$v) {_vm.gdpr=$$v},expression:"gdpr"}})]}}],null,true)}),_c('v-btn',{attrs:{"block":"","disabled":invalid,"type":"submit"}},[_vm._v(_vm._s(_vm.$t('registration.btn_register')))])],1)],1)],1)]}}])}),_c('v-dialog',{attrs:{"persistent":"","width":"auto"},model:{value:(_vm.aalert),callback:function ($$v) {_vm.aalert=$$v},expression:"aalert"}},[_c('v-card',[_c('v-card-title',{staticClass:"text-h5"},[_vm._v(" "+_vm._s(_vm.aalert_label)+" ")]),_c('v-card-text',[_vm._v(_vm._s(_vm.aalert_text))]),_c('v-card-actions',[(_vm.msg == 'NO JSON REQUEST' || _vm.msg == 'REGISTRATION ACTIVE')?_c('v-btn',{attrs:{"color":"green-darken-1","variant":"text"},on:{"click":function($event){_vm.aalert = false}}},[_vm._v("OK")]):_vm._e(),(_vm.msg == 'REGISTRATION REQUEST SENT' || _vm.msg == 'REGISTRATION PENDING')?_c('v-btn',{attrs:{"color":"green-darken-1","variant":"text"},on:{"click":function($event){return _vm.redirect_to_home();}}},[_vm._v("OK")]):_vm._e()],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }