<template>
  <v-container>
    <validation-observer ref="observer" v-slot="{ invalid }">
      <form @submit.prevent="submit">
        <v-card elevation="24">
          <v-card-title>
            {{$t('registration.title')}}
          </v-card-title>
          <v-card-text>
            <validation-provider v-slot="{ errors }" name="name" :rules="{ required: true, custom_alpha_string: /^[A-Za-zÀ-ž0-9 -]*$/ }">
              <v-text-field v-model="name" :error-messages="errors" :label="$t('registration.name')"></v-text-field>
            </validation-provider>
            <validation-provider v-slot="{ errors }" name="surname" :rules="{ required: true, custom_alpha_string: /^[A-Za-zÀ-ž0-9 -]*$/ }">
              <v-text-field v-model="surname" :error-messages="errors" :label="$t('registration.surname')"></v-text-field>
            </validation-provider>
            <validation-provider v-slot="{ errors }" name="company" :rules="{ required: true, custom_ico:8 }">
              <v-autocomplete v-model="company" :loading="ico_is_loading" :items="ico_items" :search-input.sync="ico_search" :error-messages="errors" :label="$t('registration.ico')" item-text="ico" item-value="ico" return-object hide-no-data clearable>
                <template v-slot:item="{ item }">
                  <v-list-item-content>
                    <v-list-item-title v-text="item.company"></v-list-item-title>
                    <v-list-item-subtitle v-text="item.address"></v-list-item-subtitle>
                  </v-list-item-content>
                </template>
              </v-autocomplete>
            </validation-provider>
            <v-text-field :label="$t('registration.company')" disabled v-model="ico_details"></v-text-field>
            <validation-provider v-slot="{ errors }" name="position" :rules="{ required: true, custom_alpha_string: /^[A-Za-zÀ-ž0-9 -]*$/ }">
              <v-text-field v-model="position"  :error-messages="errors" :label="$t('registration.position')"></v-text-field>
            </validation-provider>
            <validation-provider v-slot="{ errors }" name="telephone" :rules="{ required: true, custom_telephone: /^\+421[0-9]{9,9}$/ }">
              <v-text-field v-model="telephone" :error-messages="errors" :label="$t('registration.telephone')"></v-text-field>
            </validation-provider>
            <validation-provider v-slot="{ errors }" name="email" rules="required|email">
              <v-text-field v-model="email" :error-messages="errors" :label="$t('registration.email')"></v-text-field>
            </validation-provider>
            <validation-provider v-slot="{ errors }" name="password" rules="required" vid="pass_confirmation" >
              <v-text-field v-model="password" :error-messages="errors" :label="$t('registration.password')" :type="show1 ? 'text' : 'password'" :append-icon="show1 ? 'mdi-eye' : 'mdi-eye-off'" @click:append="show1 = !show1"></v-text-field>
            </validation-provider>
            <validation-provider v-slot="{ errors }" name="password_confirm" rules="required|custom_password:pass_confirmation">
              <v-text-field v-model="password_confirm" :error-messages="errors" :label="$t('registration.password_confirm')" :type="show2 ? 'text' : 'password'" :append-icon="show1 ? 'mdi-eye' : 'mdi-eye-off'" @click:append="show2 = !show2"></v-text-field>
            </validation-provider>
            <v-radio-group v-model="enews" :label="$t('registration.enews')">
              <v-radio :label="$t('registration.enews_yes')" value="yes" checked></v-radio>
              <v-radio :label="$t('registration.enews_no')" value="no"></v-radio>
            </v-radio-group>
            <validation-provider v-slot="{ errors }" :rules="{ custom_gdpr: { allowFalse: false } }" name="gdpr">
              <v-checkbox class="justifikovane" v-model="gdpr" :error-messages="errors" value="yes" :label="$t('registration.gdpr')" type="checkbox"></v-checkbox>
            </validation-provider>
            <v-btn block :disabled="invalid" type="submit">{{$t('registration.btn_register')}}</v-btn>
          </v-card-text>
        </v-card>
      </form>
    </validation-observer>
<v-dialog
      v-model="aalert"
      persistent
      width="auto"
    >
<v-card>
        <v-card-title class="text-h5">
          {{aalert_label}}
        </v-card-title>
        <v-card-text>{{aalert_text}}</v-card-text>
        <v-card-actions>
          <v-btn v-if="msg == 'NO JSON REQUEST' || msg == 'REGISTRATION ACTIVE'" color="green-darken-1" variant="text" @click="aalert = false">OK</v-btn>
          <v-btn v-if="msg == 'REGISTRATION REQUEST SENT' || msg == 'REGISTRATION PENDING'" color="green-darken-1" variant="text" @click="redirect_to_home();">OK</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
import { i18n } from "@/i18n/i18n";
import ApiService from "@/services/api_service";
//import { sha256 } from "js-sha256";

import {
  ValidationObserver,
  ValidationProvider,
  configure, 
  extend,
  setInteractionMode
} from "vee-validate";

import {
  alpha,
  confirmed,
  digits,
  email,
  regex,
  required
} from "vee-validate/dist/rules";

setInteractionMode("eager");

configure({
  defaultMessage: (field, values) => {
    // override the field name.
    // values._field_ = i18n.t(`fields.${field}`);

    return i18n.t(`validation.${values._rule_}`, values);
  }
});

extend("alpha", alpha);
extend("digits", digits);
extend("email", email);
extend("custom_gdpr", { ...required });
extend("custom_ico", { 
  validate(value, args) {
    return value.ico.length == args.length
  },
  params: ["length"]
})
extend("custom_password", { ...confirmed });
extend("custom_telephone", { ...regex });
extend("custom_alpha_string", { ...regex });
extend("required", required);

// extend("min", {
//   validate(value, args) {
//     return value.length >= args.length;
//   },
//   params: ["length"],
//   message: "The {_field_} field must have at least {length} characters"
// });

// extend("one_of", (value, values) => {
//   return values.indexOf(value) !== -1;
// });

// configure({
//   // this will be used to generate messages.
//   defaultMessage: (field, values) => {
//     values._field_ = this.$i18n.t(`registration.${field}`);
//     return this.$i18n.t(`validations.messages.${values._rule_}`, values);
//   }
// });

export default {
  components: {
    ValidationProvider,
    ValidationObserver
  },
  data: () => ({
    name: "",
    surname: "",
    company: null, // model
    ico_is_loading: false,
    ico_items: [],
    ico_search: null,
    ico_details: "",
    position: "",
    telephone: "",
    email: "",
    password: "",
    password_confirm: "",
    enews: "yes",
    gdpr: null,
    errors: null,
    show1: false,
    show2: false,
		aalert: false,
		aalert_label: "",
		aalert_text: "",
		msg: ""
  }),
  watch: {
    company(val) {
      // after ico had been selected
      if (val != null) {
       this.ico_details = val.company + ', ' + val.address
      }
      else {
        this.ico_details = ""
      }
    },
    ico_search(val) {
      // search value
      val && val !== this.company && val.length == 8 && this.querySelections(val);
    }
  },
  methods: {
		redirect_to_home() {
			this.aalert = false;
			this.$router.push('/');
		},
    querySelections(val) {
      ApiService.get(`/external/ico/${val}`)
        .then(response => {
          this.ico_items = response.data
        })
        .catch(err => {
          console.log(err)
        })
        .finally(() => (this.ico_is_loading = false))
    },
    submit() {
      this.$refs.observer.validate().then(success => {
        if (!success) {
          return;
        }
        ApiService.post(`/auth/registration`, {
          name: this.name,
          surname: this.surname,
          company: this.company,
          position: this.position,
          telephone: this.telephone,
          email: this.email,
          //password: sha256(this.password),
          password: this.password,
          enews: this.enews,
          gdpr: this.gdpr
        })
          .then(response => {
            console.log(response);
						this.msg = response['data']['msg'];
            console.log(this.msg);						
						switch(this.msg) {
							case 'NO JSON REQUEST':
							case 'MULTIPLE':
								this.aalert_label = 'Registrácia';
								this.aalert_text = 'Nastala neznáma chyba, proces registrácie prosím zopakujte neskôr.';
								//po odkliknuti nechaj formular otvoreny pre opatovne odoslanie
								break;
							case 'REGISTRATION REQUEST SENT':
								this.aalert_label = 'Registrácia';
								this.aalert_text = 'Vaša požiadavka na registráciu bola prijatá. O jej schválení Vás budeme informovať e-mailom.';
								//po odkliknuti presmeruj na domovsku stranku
								break;
							case 'REGISTRATION ACTIVE':
								this.aalert_label = 'Registrácia';
								this.aalert_text = 'Uvedená e-mailová schránka sa už používa.';
								//po odkliknuti nechaj formular otvoreny pre pripadnu zmenu emailovej adresy
								break;
							case 'REGISTRATION PENDING':
								this.aalert_label = 'Registrácia';
								this.aalert_text = 'Vaša požiadavka o registráciu ešte nebola schválená. O jej schválení Vás budeme informovať prostredníctvom e-mailu.';
								//po odkliknuti presmeruj na domovsku stranku
								break;
							default:
								this.aalert_label = '';
								this.aalert_text = '';
								break;
						}
						this.aalert = true;
            //this.$router.push('/');
          })
          .catch(() => {
          });
      });
    }
  }
};
</script>

<style scoped>
.justifikovane {
  text-align: justify;
}
</style>
